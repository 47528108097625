export const ROOT = '/';
export const HOME = '/home';
// export const INFO = '/info';
export const CANCEL = '/cancel';
export const SUCCESS = '/success';
export const PATIENT_INFO = '/patientinfo';
export const RESCHEDULE_CONFIRMATION_ROOT = '/appointment/confirmation';
export const RESCHEDULE_CONFIRMATION = '/appointment/confirmation/:id';
export const RESCHEDULE_CLINIC_AND_TIMING_ROOT = '/appointment/clinicAndTiming';
export const RESCHEDULE_CLINIC_AND_TIMING = '/appointment/clinicAndTiming/:id';

export const COVID_REPORT = '/testresult/:id';
export const COVID_REPORT_PRINT = '/testresult/print/:id';

export const SERVICE = '/service';
export const MULTISERVICE = '/service';
export const SECONDARY_SERVICE = '/secondaryservice';
export const SECONDARY_SERVICE_ORGANIZATION_SPECIFIC =
  '/secondaryservice/:organizationId';
export const SERVICE_ORGANIZATION_SPECIFIC = '/service/:organizationId';
export const MULTISERVICE_ORGANIZATION_SPECIFIC = '/service/:organizationId';
export const LOCATION = '/location';
export const LOCATION_ORGANIZATION_SPECIFIC = '/location/:organizationId';
export const PRESCREENING = '/prescreening';
export const NOTELIGIBLE = '/noteligible';
export const SUBSERVICELIST = '/subservicelist';
export const PRESCREENING_ORGANIZATION_SPECIFIC =
  '/prescreening/:organizationId';
export const NOTELIGIBLE_ORGANIZATION_SPECIFIC = '/noteligible/:organizationId';
export const SUBSERVICELIST_ORGANIZATION_SPECIFIC =
  '/subservicelist/:organizationId';

export const CLINIC_AND_TIMING = '/clinicAndTiming';
export const ADD_PATIENTS = '/addPatients';
export const CONFIRMATION = '/confirmation';
export const RESCHEDULE_SUCCESS_PAGE = '/reschedule/:id';
export const RESCHEDULE_SUCCESS_PAGE_ROOT = '/reschedule';
export const VERIFY = '/verify/:id';
export const WAIT_LIST = '/waitList';

export const EDIT_SERVICES = '/editservices/:organizationId?';

export const HOME_ORGANIZATION_SPECIFIC = '/home/:organizationId';
// export const INFO_ORGANIZATION_SPECIFIC = '/info/:organizationId';
export const SUCCESS_PAGE_ORGANIZATION_SPECIFIC = '/success/:organizationId';
export const PATIENT_INFO_ORGANIZATION_SPCIFIC = '/patientinfo/:organizationId';
export const CONFIRMATION_ORGANIZATION_SPECIFIC =
  '/confirmation/:organizationId';
export const RESCHEDULE_CONFIRMATION_ORGANIZATION_SPECIFIC =
  '/appointment/confirmation/:id/:organizationId';
export const RESCHEDULE_CLINIC_AND_TIMING_ORGANIZATION_SPECIFIC =
  '/appointment/clinicAndTiming/:id/:organizationId';
export const CLINIC_AND_TIMING_ORGANIZATION_SPECIFIC =
  '/clinicAndTiming/:organizationId';
export const ADD_PATIENTS_ORGANIZATION_SPECIFIC =
  '/addPatients/:organizationId';
export const RESCHEDULE_SUCCESS_PAGE_ORGANIZATION_SPECIFIC =
  '/reschedule/:id/:organizationId';
export const WAIT_LIST_ORGANIZATION_SPECIFIC = '/waitList/:organizationId';
export const COVID_REPORT_ORGANIZATION_SPECIFIC =
  '/testresult/:id/:organizationId';

export const CONTACT_US = '/contact-us';
export const PRIVACY_POLICY = '/privacy-policy';

export const DESIGN = '/design';

export const WELCOME = '/welcome';
export const HELP_CENTER = '/help-center';
export const CONFIRM_FOLLOWUP = '/confirm-appointment/:id/:secretKey/';

export const DME_VERIFY = '/dme/verify';
export const DME_DELIVERY_TICKET = '/dme/delivery-ticket';
export const DME_DELIVERY_TICKET_PRINT = '/dme/print/delivery-ticket';
export const DME_DELIVERY_PACKET = '/dme/delivery-packet';
export const DME_DELIVERY_PACKET_PRINT = '/dme/print/delivery-packet';

export const DME_DELIVERY_ASSESSMENT = '/dme/delivery-assessment';
export const DME_DELIVERY_ASSESSMENT_PRINT = '/dme/print/delivery-assessment';

export const DME_AUTHENTICATION = '/staff';

export const DME_DOCUMENT = '/dme/documents';
export const DME_DOCUMENT_PRINT = '/dme/print/documents';
