import ItemTable from './ItemTable/ItemTable';
import { FormSignature } from './Consent/Signature';
import PatientInfo from '../common/PatientInfo/PatientInfo';
import DeliveryInfo from '../common/DeliveryInfo/DeliveryInfo';
import PharmacyInfo from '../common/PharmaInfo/PharmacyInfo';
import ProofOfDeliveryAcknowledgement from '../../presentation/DeliveryTicket/ProofOfDeliveryAcknowledgement/ProofOfDeliveryAcknowledgement';

const DeliveryTicketForm = ({
  data,
  formErrors,
  isEdit,
  setDeliveryTicketHandler,
}) => {
  const {
    patientData,
    orderItemData,
    proofOfDeliveryData,
    deliveryInfo,
    consent = {},
    productOrder,
  } = data || {};


  return (
    <div className="group-container">
      <PharmacyInfo className="mt-6x" />
      <div className="d-flex justify-content-between gap-4">
        <PatientInfo className="mt-4x flex-1" data={patientData} />
        <DeliveryInfo className="mt-4x flex-1" data={deliveryInfo} isEdit={isEdit}  setDeliveryTicketHandler={setDeliveryTicketHandler} usedBy="delivery-ticket" />
      </div>

      <ItemTable
        tableData={orderItemData}
        productOrder={productOrder}
        isEdit={isEdit}
        setDeliveryTicketHandler={setDeliveryTicketHandler}
      />

      <ProofOfDeliveryAcknowledgement
        proofOfDeliveryData={proofOfDeliveryData}
        setDeliveryTicketHandler={setDeliveryTicketHandler}
        isEdit={isEdit}
        formErrors={formErrors?.proofOfDelivery}
      />

      {/* Signature are editable by both patient and staff, need to be revisted on v2 */}
      <FormSignature
        isEdit={isEdit}
        consent={consent}
        setDeliveryTicketHandler={setDeliveryTicketHandler}
        formErrors={formErrors?.consent}
      />
    </div>
  );
};

export default DeliveryTicketForm;
