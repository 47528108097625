import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { getString } from 'util/lang';

const LABEL_PREFIX = 'dme.deliveryTicket';

const PatientInfoPdfView = ({ name, dob, phone }) => (
  <View style={styles.pharmaInfoWrap}>
    <Text style={styles.h3}>
      {getString(`${LABEL_PREFIX}.patientDetails.title`)}
    </Text>
    <View style={styles.row}>
      <Text style={styles.p}>{getString(`${LABEL_PREFIX}.patientDetails.name`)}: </Text><Text style={styles.value}>{name}</Text>
    </View>
    <View style={styles.row}>
      <Text style={styles.p}>{getString(`${LABEL_PREFIX}.patientDetails.dob`)}: </Text><Text style={styles.value}>{dob}</Text>
    </View>
    <View style={styles.row}>
      <Text style={styles.p}>{getString(`${LABEL_PREFIX}.patientDetails.phone`)}: </Text><Text style={styles.value}>{phone}</Text>
    </View>
  </View>
);

const styles = StyleSheet.create({
  pharmaInfoWrap: {
    padding: '8px 12px',
    backgroundColor: '#FAF9F8',
    border: '0.5px solid #E1DFDD',
    gap: '8px',
    borderRadius: 4,
    flex: '1',
    marginTop: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },  
  h3: {
    fontSize: 10,
    fontWeight: 700,
  },
  p: {
    fontSize: 9,
    fontWeight: 400,
    color: '#3B3A39',
    width: 70
  },
  value: {
    fontSize: 9,
    fontWeight: 700,
  }
});

export default PatientInfoPdfView;
