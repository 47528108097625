import { printFontFamily } from 'constants/dme';
import { StyleSheet } from '@react-pdf/renderer';

export const useStyles = StyleSheet.create({
  container: {
    height: '100vh',
  },
  pdfViewer: {
    height: '100%',
    width: '100%',
  },
  page: {
    padding: '20px 16px',
    fontSize: '11px',
  },

  titleWrap: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    height: 50,
    minWidth: 50,
    width: 'auto',
    backgroundColor: '#E1DFDD',
  },
  row: {
    flexDirection: 'row',
    marginBottom: 8,
  },
  rowTable: {
    flexDirection: 'row',
  },
  alignCenter: {
    alignItems: 'flex-start',
  },
  label: {
    width: 'auto',
    fontWeight: 600,
  },
  input: {
    borderBottom: '1px solid #201F1E',
    flexGrow: 1,
    minWidth: 150,
    flexShrink: 0,
    marginLeft: 8,
  },
  body: {
    marginBottom: 16,
  },
  title: {
    fontSize: 16,
    width: 350,
    marginBottom: 24,
    marginTop: 16,
    alignSelf: 'center',
    textAlign: 'center',
    fontFamily: printFontFamily,
    fontWeight: 700,
  },
  note: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 8,
  },
  noteDesc: {
    fontSize: 11,
    marginBottom: 0,
    paddingBottom: 0,
    marginTop: 2,
  },
  noteTitle: {
    marginRight: 8,
    borderBottom: '1px solid #201F1E',
    fontSize: 11,
    fontFamily: printFontFamily,
    fontWeight: 700,
  },
  noteOptions: {
    marginLeft: 26,
    marginRight: 8,

    fontFamily: printFontFamily,
    fontWeight: 700,
  },
  description: {
    marginBottom: 16,
  },
  table: {
    marginBottom: 16,
    border: '1px solid #A19F9D',
  },
  tableHeader: {
    backgroundColor: '#f2f2f2',
    fontSize: 11,

    fontFamily: printFontFamily,
    fontWeight: 700,
    borderBottom: '1px solid #A19F9D',
  },
  cell: {
    padding: 8,
    height: '100%',
    borderRight: '1px solid #A19F9D',
  },
  cellSm: {
    padding: 6,
  },
  borderBottom: {
    borderBottom: '1px solid #201F1E',
  },
  borderRight0: {
    borderRight: 0,
  },
  flex1: {
    flex: 1,
  },
  flex2: {
    flex: 2,
  },
  flex3: {
    flex: 3,
  },
  sectionTitle: {
    fontFamily: printFontFamily,
    fontWeight: 700,
    marginBottom: 8,
  },
  fontWt700: {
    fontFamily: printFontFamily,
    fontWeight: 700,
  },
  fontWt400: {
    fontFamily: printFontFamily,
    fontWeight: 400,
  },
  fontWt600: {
    fontWeight: 600,
  },
  optionText: {
    marginBottom: 4,
    marginLeft: 8,
  },
  optionsTable: {
    marginBottom: 16,
  },
  optionsTableHeader: {
    flexDirection: 'row',
    marginBottom: 8,
    padding: '4px 6px',
    backgroundColor: '#F3F2F1',
    borderBottom: '1px solid #A19F9D',
  },
  checkbox: {
    height: 10,
    width: 10,
    border: '1px solid #201F1E',
  },
  checkboxLabel: {
    marginRight: 8,
    fontSize: 11,

    fontFamily: printFontFamily,
    fontWeight: 700,
  },
  checkboxDescription: {
    flex: 1,
    marginTop: 2,
  },
  textArea: {
    padding: 8,
    height: 90,
  },
  signatureLabel: {
    width: 80,

    fontFamily: printFontFamily,
    fontWeight: 700,
  },
  signatureDesc: {
    minHeight: 20,
  },
  dateLabel: {
    width: 40,

    fontFamily: printFontFamily,
    fontWeight: 700,
  },
  notice: {
    marginBottom: 16,
  },
  accessibilityNotice: {
    marginBottom: 8,
    fontSize: 11,
  },
  legalNotice: {
    marginBottom: 16,
  },
  legalText: {
    fontSize: 9,
    textAlign: 'justify',
  },
  mt4: {
    marginTop: 16,
  },
  mt0: {
    marginTop: 0,
  },
  mb0: {
    marginBottom: 0,
  },
  ml2: {
    marginLeft: 8,
  },
  mb1: {
    marginBottom: 4,
  },
  pb2: {
    paddingBottom: 8,
  },
  footer: {
    width: '100%',
    borderTop: '1px solid #201F1E',
    paddingTop: 4,
    fontSize: 11,
    position: 'absolute',
    bottom: 16,
    left: 16,
  },
  justifyBetween: {
    justifyContent: 'space-between',
  },
  img: {
    height: 10,
    width: 10,
    marginRight: 6,
    marginTop: 3,
  },
  signImage: {
    maxWidth: 215,
    paddingLeft: 4,
    paddingBottom: 6,
  },
});
