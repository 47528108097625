import { useCallback, useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Button from 'components/common/Button';
import { getString } from 'util/lang';
import styles from './DMESignature.module.scss';

const LABEL_PREFIX = 'dme.deliveryTicket.setupSignature';
/**
 * Renders a signature input canvas area
 *
 * @param {string} title
 * @param {string} signature
 * @param {{function}} onStrokeEnd: Called when
 */
const DMESignature = ({ title, signature, onStrokeEnd, disabled }) => {
  const signatureRef = useRef();

  const renderPreviousSignatureHandler = useCallback(() => {
    if (!signatureRef.current || !signature || !signatureRef.current.isEmpty())
      return;

    signatureRef.current.fromDataURL(signature);
  }, [signature]);

  const clearSignature = () => {
    signatureRef.current.clear();
    onStrokeEnd('');
  };

  const onStrokeEndHandler = () => {
    if (!signatureRef.current) return;

    const signature = signatureRef.current.toDataURL();
    onStrokeEnd(signature);
  };

  useEffect(() => {
    renderPreviousSignatureHandler();
  }, [renderPreviousSignatureHandler]);

  useEffect(() => {
    const ref = signatureRef.current;
    if (!ref) return;

    const handleResize = () => {
      const signature = ref.toDataURL();
      ref._resizeCanvas();
      ref.fromDataURL(signature);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [signatureRef.current]);

  useEffect(() => {
    if (disabled && signatureRef.current) {
      signatureRef.current.off();
    }
  }, [disabled, signatureRef]);

  return (
    <div className={styles.dmeSignWrap}>
      <h3>{title}</h3>
      <span>{getString(`${LABEL_PREFIX}.subTitle`)}</span>
      <div className={styles.dmeSign}>
        <div className={styles.dmeSignCanvas}>
          <SignatureCanvas
            ref={signatureRef}
            canvasProps={{
              className: 'signature-canvas',
            }}
            maxWidth={1.5}
            throttle={0}
            onEnd={onStrokeEndHandler}
            clearOnResize={false}
          />
        </div>
        {!disabled && (
          <div className={styles.dmeSignButton}>
            <Button
              label={getString(`${LABEL_PREFIX}.clearSignature`)}
              title={getString(`${LABEL_PREFIX}.clearSignature`)}
              isEnabled
              onClick={clearSignature}
              className="btn btn--large btn-primary--outline"
              isActive
              tabIndex="0"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DMESignature;
