import { useEffect, useState } from 'react';
import { Route, useParams } from 'react-router-dom';

import { httpStatusCodes } from 'constants/statusCodes';
import {
  useLazyGetDeliveryPacketQuery,
  useLazyGetDeliveryTicketQuery,
  useLazyVerifyStaffQuery,
  useLazyGetRequiredDocumentQuery,
} from 'redux/dme/dme.api';
import {
  clearDMETokenFromSessionStorage,
  getDataFromSessionStorage,
  storeDataToSessionStorage,
} from 'util/sessionStorage';
import { useNavigationHelper } from 'hooks/useNavigationHelper';
import getRedirectedToDMEVerifyFrom from '../utils/getRedirectedToDMEVerifyFrom';
import { REDIRECTED_TO_DME_VERIFY_FROM } from '../constants/redirectedToDMEVerifyFrom';
import { DME_TOKEN } from 'constants/dme';
import { useContactId } from '../hooks/useContactId';
import { ActivityIndicatorOverlay } from 'components/common/ActivityIndicator';

const WithDMETokenCheck = ({ children, from }) => {
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const productOrderId = params?.productOrderId;
  const deliveryTicketId = params?.deliveryTicketId;

  const { contactId, isStaff } = useContactId();

  const [triggerVerifyStaff] = useLazyVerifyStaffQuery();
  const [
    triggerGetDeliveryTicket,
    { isError: isTicketError, error: ticketError },
  ] = useLazyGetDeliveryTicketQuery();
  const [
    triggerGetDeliveryPacket,
    { isError: isPacketError, error: packetError },
  ] = useLazyGetDeliveryPacketQuery();

  const [
    triggerGetRequiredDocument,
    { isError: isRequiredDocumentError, error: documentError },
  ] = useLazyGetRequiredDocumentQuery();

  const isError = isTicketError || isPacketError || isRequiredDocumentError;
  const error = ticketError || packetError || documentError;

  const nav = useNavigationHelper();
  const patientToken = getDataFromSessionStorage(DME_TOKEN);

  const fetchDeliveredAPI = () => {
    switch (from) {
      case REDIRECTED_TO_DME_VERIFY_FROM.DELIVERY_PACKET:
        triggerGetDeliveryPacket(productOrderId);
        setIsLoading(false);
        return;
      case REDIRECTED_TO_DME_VERIFY_FROM.DELIVERY_TICKET:
      case REDIRECTED_TO_DME_VERIFY_FROM.DELIVERY_ASSESSMENT:
        triggerGetDeliveryTicket({ productOrderId, deliveryTicketId });
        setIsLoading(false);
        return;
      case REDIRECTED_TO_DME_VERIFY_FROM.DOCUMENT:
        triggerGetRequiredDocument(productOrderId);
        setIsLoading(false);
        return;
      default:
        return;
    }
  };

  const verifyStaff = async () => {
    const { data } = await triggerVerifyStaff({ contactId, productOrderId });
    const staffToken = data?.data?.token;

    if (!staffToken) {
      nav.dme.deliveryVerifyPage({ productOrderId, deliveryTicketId, from });
      return;
    }

    storeDataToSessionStorage(DME_TOKEN, staffToken);
    fetchDeliveredAPI();
  };

  useEffect(() => {
    if (!isError) return;

    // for delivery ticket or packet not found
    if (error?.status === httpStatusCodes.NOT_FOUND) {
      return;
    }

    if (isStaff) {
      verifyStaff();
      return;
    }

    if (
      [httpStatusCodes.BAD_REQUEST, httpStatusCodes.UNAUTHORIZED].includes(
        error.status,
      )
    ) {
      clearDMETokenFromSessionStorage();
      nav.dme.deliveryVerifyPage({ productOrderId, deliveryTicketId, from });
    }
  }, [isError, error, isStaff]);

  useEffect(() => {
    if (isStaff) {
      verifyStaff();
      return;
    }
    if (!patientToken) {
      return nav.dme.deliveryVerifyPage({
        productOrderId,
        deliveryTicketId,
        from,
      });
    }

    if (patientToken) {
      fetchDeliveredAPI();
      return;
    }

    if (!isStaff) {
      nav.dme.deliveryVerifyPage({ productOrderId, deliveryTicketId, from });
      return;
    }
  }, []);

  if (isLoading) {
    return <ActivityIndicatorOverlay />;
  }

  return children;
};

const DMEProtectedRoute = ({ component: Component, ...props }) => {
  const { path } = props;
  const from = getRedirectedToDMEVerifyFrom(path);

  return (
    <Route
      {...props}
      render={() => (
        <WithDMETokenCheck from={from}>
          <Component />
        </WithDMETokenCheck>
      )}
    />
  );
};

export default DMEProtectedRoute;
