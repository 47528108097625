import React from 'react';
import { FiCheck } from 'vyaguta-icons/fi';

import { toast } from 'util/toast';
import { getString } from 'util/lang';
import { useAppSettings } from 'context';
import { concatenateWithSeparator } from 'util/string';
import { downloadFile, generatePdfBlob } from 'util/printPdf';
import ABNFormPdfView from '../PDFView/ABNForm/ABNFormPdfView';
import { componentKeys } from '../../../container/DmeDocument';
import TermsAndConditionsPdfView from '../PDFView/TermsAndConditions';

export const ConfirmationPage = ({
  documents,
  isMedicare,
  productOrder,
  organizationName,
  primaryPayerName,
  signedDocumentDetail,
}) => {
  const appData = useAppSettings();
  const { logoUrl } = appData;

  const handlePrint = async () => {
    const printableDocuments = [];

    documents.forEach((document) => {
      switch (document.type) {
        // Temporary removal of Esignature

        // case componentKeys.esignature:
        //   printableDocuments.push({
        //     component: (
        //       <ElectronicSignaturePdfView
        //         signedDocumentDetail={signedDocumentDetail}
        //         appData={appData}
        //       />
        //     ),
        //     filename: 'ElectronicSignature.pdf',
        //   });
        //   break;
        case componentKeys.termAndCondition:
          printableDocuments.push({
            component: (
              <TermsAndConditionsPdfView
                signedDocumentDetail={signedDocumentDetail}
                appData={appData}
              />
            ),
            filename: 'TermsAndConditions.pdf',
          });
          break;
        case componentKeys.abnForm:
          printableDocuments.push({
            component: (
              <ABNFormPdfView
                isMedicare={isMedicare}
                orderItem={document.orderItem}
                productOrder={productOrder}
                notifier={organizationName}
                primaryPayerName={primaryPayerName}
                signedDocumentDetail={signedDocumentDetail}
              />
            ),
            filename: `ABN-${concatenateWithSeparator(document.orderItem.hcpcsName, document.orderItem.hcpcsShortDescription, '-')}.pdf`,
          });
      }
    });

    let allDownloadsSuccessful = true;

    for (const { component, filename } of printableDocuments) {
      try {
        const blob = await generatePdfBlob(component);
        downloadFile(blob, filename);
      } catch (error) {
        allDownloadsSuccessful = false;
        toast.error({
          title: '',
          message: `Error downloading ${filename}: ${error.message}`,
        });
      }
    }

    if (allDownloadsSuccessful) {
      toast.success({
        title: '',
        message: 'Documents downloaded successfully.',
      });
    } else {
      toast.error({
        title: '',
        message: 'Some documents failed to download.',
      });
    }
  };
  return (
    <div className="wrapper">
      <div className="header">
        <div className="logo">
          <img src={logoUrl} alt="pharmacy_logo" />
        </div>
      </div>
      <div className="card__container">
        <div className="card card__success card__success--lg mb-0x">
          <div className="card--content ">
            <FiCheck className="success-check success-check--green m-auto" />
            <h3 className="mt-9x-md my-3x">
              {getString('dme.documentation.confirmation.signatureCaptured')}
            </h3>
            <p>
              {getString('dme.documentation.confirmation.confirmationMessage')}
            </p>
            <p className="mb-0x border-top pt-6x">
              {getString(
                'dme.documentation.confirmation.confirmationLinkMessage',
              )}
              <span className="btn--link" onClick={() => handlePrint()}>
                {getString('dme.documentation.confirmation.clickLink')}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
