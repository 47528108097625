import { getString } from 'util/lang';
import { Text, View, Image } from '@react-pdf/renderer';

import { useStyles } from '../ABNFormPdf.styles';

export const ABNFormSignature = ({ signedOrderItemData }) => {
  const styles = useStyles;

  const renderSignatureView = () => {
    return signedOrderItemData.abnSignature ? (
      <Text
        style={[
          styles.borderBottom,
          styles.mt4,
          styles.pb2,
          styles.signatureDesc,
        ]}
      >
        {signedOrderItemData.abnSignature}
      </Text>
    ) : (
      <Image
        src={signedOrderItemData.abnCanvasSignature}
        style={styles.signImage}
      />
    );
  };

  return (
    <View style={[styles.table, styles.row]}>
      <View style={[styles.cell, styles.flex3]}>
        <Text style={styles.signatureLabel}>
          {getString('dme.documentation.abnForm.fields.signatureLabel')}:
        </Text>
        <>{renderSignatureView()}</>
      </View>
      <View style={[styles.cell, styles.flex1, styles.borderRight0]}>
        <Text style={styles.dateLabel}>
          {getString('dme.documentation.abnForm.fields.dateLabel')}:
        </Text>
        <Text
          style={[
            styles.borderBottom,
            styles.mt4,
            styles.pb2,
            styles.signatureDesc,
          ]}
        >
          {signedOrderItemData.abnSignedDate}
        </Text>
      </View>
    </View>
  );
};
