export const DME_TOKEN = 'DME_TOKEN';

export const ABNLang = {
  MEDICARE: 'medicare',
  NON_MEDICARE: 'nonMedicare',
};

export const printFontFamily = 'Segoe UI';

export const printFontRegularCDN =
  'https://fonts.cdnfonts.com/s/15051/Segoe UI.woff';

export const printFontBoldCDN =
  'https://fonts.cdnfonts.com/s/15051/Segoe UI Bold.woff';

  export const printFontItalicCDN =
  'https://fonts.cdnfonts.com/s/15051/Segoe UI Italic.woff';