import { getString } from 'util/lang';
import DateInput from 'components/common/DateInput';
import FormInput from 'components/common/FormInput';
import { getDisplayDate } from 'util/DateAndTime';
import { useEffect } from 'react';
import DMESignature from 'components/COVID/views/DME/presentation/common/DMESignature/DMESignature';

export const ABNFormSignature = ({
  signedOrderItemData,
  onChange,
  errors,
  orderItemId,
}) => {
  const onStrokeEndHandler = (signature) => {
    onChange('abnCanvasSignature', signature, orderItemId);
  };

  const renderSignature = () => {
    return signedOrderItemData.abnSignature ? (
      <>
        <h3 className="title title--bold mb-2x">
          {getString('dme.documentation.abnForm.fields.signatureLabelFull')}
        </h3>
        <FormInput
          id="abnSignature"
          labelClassName="label-placeholder label-placeholder--required"
          label={getString(
            'dme.documentation.abnForm.fields.patientFullNameLabel',
          )}
          value={signedOrderItemData.abnSignature ?? ''}
          hasError={!!errors['abnSignature']}
          solo
          required
          onChange={(id, value) => onChange(id, value, orderItemId)}
          disabled
        />
        <p className="desc desc--xs color-grey-text-light mt-1x mb-0x">
          {getString('dme.documentation.abnForm.signatureNote')}
        </p>{' '}
      </>
    ) : (
      <>
        <DMESignature
          title={getString('dme.documentation.abnForm.fields.signatureLabel')}
          signature={signedOrderItemData.abnCanvasSignature}
          onStrokeEnd={onStrokeEndHandler}
        />
        {errors?.['abnCanvasSignature'] && (
          <div className="form-error mt-2x">
            {errors?.['abnCanvasSignature']}
          </div>
        )}
      </>
    );
  };

  // Set Date to current date for date picker input on component mount
  useEffect(() => {
    if (!signedOrderItemData.abnSignedDate) {
      onChange('abnSignedDate', getDisplayDate(new Date()), orderItemId);
    }
  }, [orderItemId]);

  return (
    <>
      <p className="desc">
        {getString('dme.documentation.abnForm.signatureInfo')}
      </p>
      <div className="form__wrapper border-rounded mb-6x">
        <div className="row">
          <div className="col-8 py-4x px-6x border-right">
            <div className="row">
              <div className="col-11">{renderSignature()}</div>
            </div>
          </div>
          <div className="col-4 px-6x pt-6x">
            <DateInput
              id="abnSignedDate"
              labelClassName="label-placeholder label-placeholder--required"
              label={getString('dme.documentation.abnForm.fields.dateLabel')}
              required={true}
              hasError={!!errors['abnSignedDate']}
              solo
              value={
                signedOrderItemData.abnSignedDate
                  ? new Date(signedOrderItemData.abnSignedDate)
                  : ''
              }
              handleDateChange={(id, date) =>
                onChange(id, getDisplayDate(date), orderItemId)
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
