import { useEffect } from 'react';
import { getString } from 'util/lang';
import { getDisplayDate } from 'util/DateAndTime';
import DateInput from 'components/common/DateInput';
import FormInput from 'components/common/FormInput';
import { authorizationFields } from '../../../validation/AuthorizationValidation';
import DMESignature from '../../common/DMESignature/DMESignature';

const LABEL_PREFIX = 'dme.deliveryTicket.consent';

export const FormSignature = ({
  isEdit = false,
  deliveryPacketSignature,
  deliveryPacketBeneficiarySignature,
  deliveryPacketSignatureDate,
  inputChangeHandler,
  formErrors,
}) => {
  const onStrokeEndHandler = (signature) => {
    inputChangeHandler(
      authorizationFields.deliveryPacketBeneficiarySignature,
      signature,
    );
  };

  const renderSignature = () => {
    return deliveryPacketSignature ? (
      <>
        <h3>
          {getString('dme.deliveryPacketForm.authorizationForm.signatureTitle')}
        </h3>
        <h3 className="title title--small mb-2x">
          {getString(`${LABEL_PREFIX}.fullName`)}
        </h3>
        <FormInput
          id={authorizationFields.deliveryPacketSignature}
          labelClassName="label-placeholder label-placeholder--required"
          label={getString(`${LABEL_PREFIX}.fullName`)}
          value={deliveryPacketSignature ?? ''}
          hasError={!!formErrors[authorizationFields.deliveryPacketSignature]}
          solo
          required
          disabled
          onChange={(id, value) => inputChangeHandler(id, value)}
        />
        <p className="desc desc--xs color-grey-text-light mt-1x mb-0x">
          {getString(`${LABEL_PREFIX}.disclaimer`)}
        </p>
      </>
    ) : (
      <>
        <DMESignature
          disabled={!isEdit}
          title={getString(
            'dme.deliveryPacketForm.authorizationForm.signatureTitle',
          )}
          signature={deliveryPacketBeneficiarySignature}
          onStrokeEnd={onStrokeEndHandler}
        />
        {formErrors[authorizationFields.deliveryPacketBeneficiarySignature] && (
          <div className="form-error mt-2x">
            {formErrors[authorizationFields.deliveryPacketBeneficiarySignature]}
          </div>
        )}
      </>
    );
  };

  // Set Date to current date for date picker input on component mount
  useEffect(() => {
    if (!deliveryPacketBeneficiarySignature) {
      inputChangeHandler(
        authorizationFields.deliveryPacketSignatureDate,
        getDisplayDate(new Date()),
      );
    }
  }, [deliveryPacketSignatureDate]);

  return (
    <div className="form__wrapper border-rounded my-6x">
      <div className="row">
        <div className="col-8 py-4x px-6x border-right">
          <div className="row">
            <div className="col-11">{renderSignature()}</div>
          </div>
        </div>
        <div className="col-4 py-4x px-6x deliverySignature__date">
          <h3 className="title title--small mb-2x">
            {getString(`${LABEL_PREFIX}.date`)}
          </h3>
          <DateInput
            id={authorizationFields.deliveryPacketSignatureDate}
            labelClassName="label-placeholder label-placeholder--required"
            label={getString(`${LABEL_PREFIX}.date`)}
            required={true}
            hasError={
              !!formErrors[authorizationFields.deliveryPacketSignatureDate]
            }
            solo
            disableInput={!isEdit}
            value={
              deliveryPacketSignatureDate
                ? new Date(deliveryPacketSignatureDate)
                : ''
            }
            handleDateChange={(id, date) =>
              inputChangeHandler(id, getDisplayDate(date))
            }
          />
        </div>
      </div>
    </div>
  );
};
