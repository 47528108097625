import { getString } from 'util/lang';

export const validateDeliveryTicket = (data) => {
  let hasError = false;
  const errors = {
    proofOfDelivery: {},
    consent: {},
    setupSignatureData: '',
    individualResponsibleSignature,
  };

  // Validating date in questionnaire
  const consentData = data?.consent;
  const setupSignatureData = data?.setupSignatureData;
  const individualResponsibleSignature = data?.individualResponsibleSignature;

  // Temporary removal of previous FormInput implementation of signature
  // if (!consentData.signature) {
  //   errors.consent.signature = getString(
  //     'dme.validation.requiredFieldMessage',
  //     {
  //       field: getString('dme.deliveryPacketForm.authorizationForm.signature'),
  //     },
  //   );
  //   hasError = true;
  // }

  // Previous FormInput implementation of signature is conditionally displayed for backward compatibility
  if (!consentData.proofOfDeliverySignature && !consentData.signature) {
    errors.consent.proofOfDeliverySignature = getString(
      'dme.validation.requiredFieldMessage',
      {
        field: getString(
          'dme.deliveryTicket.proofOfAcknowledgement.proofOfDelivery',
        ),
      },
    );
    hasError = true;
  }

  if (!consentData.signedDate) {
    errors.consent.signedDate = getString(
      'dme.validation.requiredFieldMessage',
      {
        field: getString('dme.deliveryPacketForm.authorizationForm.signedDate'),
      },
    );
    hasError = true;
  }
  // Temporary removal of previous FormInput implementation of signature
  // if (!setupSignatureData) {
  //   errors.setupSignatureData = getString(
  //     'dme.validation.requiredFieldMessage',
  //     {
  //       field: getString('dme.deliveryPacketForm.authorizationForm.signedDate'),
  //     },
  //   );
  //   hasError = true;
  // }

  // Previous FormInput implementation of signature is conditionally displayed for backward compatibility
  if (!individualResponsibleSignature && !setupSignatureData) {
    errors.individualResponsibleSignature = getString(
      'dme.validation.requiredFieldMessage',
      {
        field: getString(
          'dme.deliveryTicket.consent.individualResponsibleForDispensingOrSetup',
        ),
      },
    );
    hasError = true;
  }

  return { errors, hasError };
};
