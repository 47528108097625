import { FiCheck } from 'vyaguta-icons/fi';

import { getString } from 'util/lang';
import YesNoCheckboxes from '../YesNoButtons/YesNoCheckboxes';
import LineBreak from '../../common/LineBreak/LineBreak';

const LABEL_PREFIX = 'dme.deliveryTicket.settingAndTestingForm';

const productSettingChecklist = [
  { key: 'assembly', question: getString(`${LABEL_PREFIX}.assembly`) },
  {
    key: 'battery_inserted',
    question: getString(`${LABEL_PREFIX}.batteryInserted`),
  },
  {
    key: 'patient_sizing',
    question: getString(`${LABEL_PREFIX}.patientSizing`),
  },
  {
    key: 'item_programmed',
    question: getString(`${LABEL_PREFIX}.itemProgrammed`),
  },
  { key: 'other_setting', question: getString(`${LABEL_PREFIX}.other`) },
];

export const settingKeyToQuestionMap = productSettingChecklist.reduce(
  (acc, curr) => {
    const { key, question } = curr;
    acc[key] = question;
    return acc;
  },
  {},
);

export const initialSettingValues = productSettingChecklist.map(({ key }) => ({
  key,
  checked: false,
}));

const productTestingChecklist = [
  {
    key: 'functions_according_to_guidelines',
    question: getString(`${LABEL_PREFIX}.functionsAccordingToGuidelines`),
  },
  {
    key: 'structurally_sound',
    question: getString(`${LABEL_PREFIX}.structurallySound`),
  },
];

export const testingKeyToQuestionMap = productTestingChecklist.reduce(
  (acc, curr) => {
    const { key, question } = curr;
    acc[key] = question;
    return acc;
  },
  {},
);

export const initialTestingValues = productTestingChecklist.map(({ key }) => ({
  key,
  // checked: false  // For both Yes and No unchecked, checked value should be undefined
}));

/**
 * Product Setting and Testing Form Component - Delivery Ticket - DME
 *
 * @param {object} productSettingAndTestingData
 * @param {func} setProductSettingAndTestingData
 */
const ProductSettingAndTestingForm = ({
  settingAndTestingData,
  setDeliveryTicketHandler,
  isEdit,
}) => {
  const { settings, tests } = settingAndTestingData;

  return (
    <div className="delivery-ticket__testing_form--wrap">
      <div className="delivery-ticket__testing_form">
        <h3>{getString(`${LABEL_PREFIX}.title`)}</h3>
        <div className="row delivery-ticket__testing_form--checkboxes">
          {settings.map((setting) => {
            const { key, checked } = setting;
            const question = settingKeyToQuestionMap[key];

            return (
              <div
                key={key}
                className="custom-check delivery-ticket__testing_form--checkbox"
              >
                <input
                  autoComplete="off"
                  type="checkbox"
                  id={key}
                  name={question}
                  disabled={!isEdit}
                  onChange={() => {
                    setDeliveryTicketHandler(
                      {
                        ...settingAndTestingData,
                        settings: settings.map((s) => {
                          if (key === s.key) {
                            return {
                              ...s,
                              checked: !s.checked,
                            };
                          }
                          return s;
                        }),
                      },
                      'settingAndTestingData',
                    );
                  }}
                  value={checked}
                  checked={checked}
                />
                <label
                  htmlFor={key}
                  className="custom-check delivery-ticket__testing_form--checkbox_text"
                >
                  <FiCheck /> {question}
                </label>
              </div>
            );
          })}
        </div>
        <table className="delivery-ticket__testing_form--radios">
          <tbody>
            {tests.map((test) => {
              const { key, checked } = test;
              const question = testingKeyToQuestionMap[key];

              return (
                <tr key={key}>
                  <td className="delivery-ticket__testing_form--radios-right">
                    <p>{question}</p>
                  </td>
                  <td className="delivery-ticket__testing_form--radios-left">
                    <YesNoCheckboxes
                      name={key}
                      yesKey={`${key}-yes`}
                      checked={checked}
                      noKey={`${key}-no`}
                      disabled={!isEdit}
                      onCheck={(checked) => {
                        setDeliveryTicketHandler(
                          {
                            ...settingAndTestingData,
                            tests: tests.map((t) => {
                              if (key === t.key) {
                                return {
                                  ...t,
                                  checked,
                                };
                              }
                              return t;
                            }),
                          },
                          'settingAndTestingData',
                        );
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductSettingAndTestingForm;
