import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { getString } from 'util/lang';
import { joinValues } from 'util/string';

const LABEL_PREFIX = 'dme.deliveryTicket';

export const getTableHead = () => [
  {
    key: 'hcpcs',
    val: getString(`${LABEL_PREFIX}.itemTableHead.hcpcs`),
    flex: 3.5,
  },
  {
    key: 'lotOrSerialNo',
    val: getString(`${LABEL_PREFIX}.itemTableHead.lotOrSerialNo`),
    flex: 2,
  },
  {
    key: 'manufacturer',
    val: getString(`${LABEL_PREFIX}.itemTableHead.manufacturer`),
    flex: 2,
  },
  {
    key: 'purchaseType',
    val: getString(`${LABEL_PREFIX}.itemTableHead.purchaseType`),
    flex: 2,
  },
  {
    key: 'warranty',
    val: getString(`${LABEL_PREFIX}.itemTableHead.warranty`),
    flex: 2,
  },
  {
    key: 'qty',
    val: getString(`${LABEL_PREFIX}.itemTableHead.quantity`),
    flex: 0.5,
  },
  {
    key: 'unitPrice',
    val: getString(`${LABEL_PREFIX}.itemTableHead.billedPrice`),
    flex: 1,
  },
];

const ItemTablePdfView = ({ tableData }) => {
  return (
    <View style={styles.tableWrap}>
      <View style={styles.tableHeadWrap}>
        {getTableHead().map((tHead, index) => (
          <View
            style={{ ...styles.tableCellWrap, flex: tHead.flex }}
            key={tHead.key + index}
          >
            <Text style={styles.tableHeadText}>{tHead.val}</Text>
          </View>
        ))}
      </View>
      {tableData?.map((tBody, index) => (
        <View key={index} style={styles.tableBodyWrap}>
          <View style={styles.tableBody}>
            <View
              style={{ ...styles.tableCellWrap, flex: 3.5 }}
              key={tBody.key + index}
            >
              <Text style={styles.tableBodyText}>
                {joinValues([tBody.hcpcsCode, tBody.hcpcsDescription], ' - ')}
              </Text>
            </View>
            <View
              style={{ ...styles.tableCellWrap, flex: 2 }}
              key={tBody.key + index}
            >
              <Text style={styles.tableBodyText}>{tBody.serialNumber}</Text>
            </View>
            <View
              style={{ ...styles.tableCellWrap, flex: 2 }}
              key={tBody.key + index}
            >
              <Text style={styles.tableBodyText}>{tBody.manufacturer}</Text>
            </View>
            <View
              style={{ ...styles.tableCellWrap, flex: 2 }}
              key={tBody.key + index}
            >
              <Text style={styles.tableBodyText}>{tBody.purchaseType}</Text>
            </View>
            <View
              style={{ ...styles.tableCellWrap, flex: 2 }}
              key={tBody.key + index}
            >
              <Text style={styles.tableBodyText}>
                {tBody.warrantyInMonths ?? '-'} Months
              </Text>
              <Text style={styles.tableBodyText}>
                {tBody.warrantyInYears ?? '-'} Years
              </Text>
            </View>
            <View
              style={{ ...styles.tableCellWrap, flex: 0.5 }}
              key={tBody.key + index}
            >
              <Text style={styles.tableBodyText}>{tBody.quantity}</Text>
            </View>
            <View
              style={{ ...styles.tableCellWrap, flex: 1 }}
              key={tBody.key + index}
            >
              <Text style={styles.tableBodyText}>{tBody.billedPrice}</Text>
            </View>
          </View>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  tableWrap: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
  },
  tableHeadWrap: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#F3F2F1',
    border: '0.5px solid #E1DFDD',
    borderRight: 'none',
  },
  tableCellWrap: {
    padding: '12px 16px',
    borderRight: '0.5px solid #E1DFDD',
  },
  tableHeadText: {
    fontSize: 9,
    fontWeight: 700,
  },
  tableBodyText: {
    fontSize: 9,
    color: '#201F1E',
  },
  tableBodyWrap: {
    border: '0.5px solid #E1DFDD',
    borderTop: 'none',
    display: 'flex',
    flexDirection: 'column',
  },
  tableBody: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export default ItemTablePdfView;
