import { useEffect } from 'react';
import { getString } from 'util/lang';
import { getDisplayDate } from 'util/DateAndTime';
import DateInput from 'components/common/DateInput';
import FormInput from 'components/common/FormInput';
import DMESignature from '../../common/DMESignature/DMESignature';

const LABEL_PREFIX = 'dme.deliveryTicket.consent';

export const FormSignature = ({
  isEdit = false,
  consent,
  setDeliveryTicketHandler,
  formErrors,
}) => {
  const { signedDate, signature, proofOfDeliverySignature } = consent;

  const onStrokeEndHandler = (signature) => {
    setDeliveryTicketHandler(
      { ...consent, proofOfDeliverySignature: signature },
      'consent',
    );
  };

  const renderSignature = () => {
    return signature ? (
      <>
        <h3 className="title title--small mb-2x">
          {getString(`${LABEL_PREFIX}.fullName`)}
        </h3>
        <FormInput
          id="signature"
          labelClassName="label-placeholder label-placeholder--required"
          label={getString(`${LABEL_PREFIX}.fullName`)}
          value={signature ?? ''}
          hasError={!!formErrors?.['signature']}
          solo
          required
          disabled
          onChange={(_id, value) =>
            setDeliveryTicketHandler(
              { ...consent, signature: value },
              'consent',
            )
          }
        />
        <p className="desc desc--xs color-grey-text-light mt-1x mb-0x">
          {getString(`${LABEL_PREFIX}.disclaimer`)}
        </p>{' '}
      </>
    ) : (
      <>
        <DMESignature
          title={getString(
            'dme.deliveryTicket.proofOfAcknowledgement.signature',
          )}
          signature={proofOfDeliverySignature}
          onStrokeEnd={onStrokeEndHandler}
        />

        {formErrors?.['proofOfDeliverySignature'] && (
          <div className="form-error mt-2x">
            {formErrors?.['proofOfDeliverySignature']}
          </div>
        )}
      </>
    );
  };

  // Set Date to current date for date picker input on component mount
  useEffect(() => {
    if (!proofOfDeliverySignature) {
      setDeliveryTicketHandler(
        { ...consent, signedDate: getDisplayDate(new Date()) },
        'consent',
      );
    }
  }, [signedDate]);

  return (
    <div className="form__wrapper border-rounded my-6x">
      <div className="row">
        <div className="col-8 py-4x px-6x border-right">
          <div className="row">
            {signature && (
              <h3 className="title bold mb-0x ml-3x">
                {getString(
                  'dme.deliveryTicket.proofOfAcknowledgement.signature',
                )}
              </h3>
            )}
            <div className="col-11">{renderSignature()}</div>
          </div>
        </div>
        <div className="col-4 py-4x px-6x deliverySignature__date">
          <h3 className="title title--small mb-2x">
            {getString(`${LABEL_PREFIX}.date`)}
          </h3>
          <DateInput
            id="signedDate"
            labelClassName="label-placeholder label-placeholder--required"
            label={getString(`${LABEL_PREFIX}.date`)}
            required={true}
            hasError={!!formErrors?.['signedDate']}
            solo
            disableInput={!isEdit}
            value={signedDate ? new Date(signedDate) : ''}
            handleDateChange={(_id, date) =>
              setDeliveryTicketHandler(
                { ...consent, signedDate: getDisplayDate(date) },
                'consent',
              )
            }
          />
        </div>
      </div>
    </div>
  );
};
