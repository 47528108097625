import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { iconCheckboxChecked, iconCheckboxUnchecked, iconInfoPdf } from 'assets/images';
import { homeAssessmentToQuestionMap } from './HomeAssessmentAndSafety';
import { getString } from 'util/lang';

const LABEL_PREFIX = 'dme.deliveryTicket.homeAssessmentAndSafety';

const HomeAssessmentAndSafetyPdfView = ({ homeAssessmentData }) => {
  return (
    <View style={styles.container}>
      <View style={styles.headerWrap}>
        <Text style={styles.headerText}>
          {getString(`${LABEL_PREFIX}.title`)}
        </Text>
      </View>

      <View style={styles.alertWrap}>
        <Image
          src={iconInfoPdf}
          style={styles.infoImg}
          alt={'info'}
        />
        <Text style={styles.alert}>{getString(`${LABEL_PREFIX}.info`)}</Text>
      </View>

      <Text style={styles.desc}>{getString(`${LABEL_PREFIX}.content`)}</Text>

      {homeAssessmentData?.map((document) => {
        const { key, checked, additionalInfo } = document;
        const question = homeAssessmentToQuestionMap[key];

        return (
          <View key={key} style={styles.documentRow}>
            {/* Display checkbox checked/unchecked based on document.checked */}
            <View style={styles.checkboxWrap}>
              <Image
                src={checked ? iconCheckboxChecked : iconCheckboxUnchecked}
                style={styles.checkboxImg}
                alt={checked ? 'checked' : 'unchecked'}
              />
              <Text style={styles.documentLabel}>{question}</Text>
            </View>

            {/* Display additionalInfo if 'other' */}
            {key === 'other' && additionalInfo && (
              <Text style={styles.additionalInfo}>{additionalInfo}</Text>
            )}
          </View>
        );
      })}
    </View>
  );
};

// Styles for PDF rendering
const styles = StyleSheet.create({
  container: {
    marginTop: 15,
  },
  alertWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  alert: {
    fontSize: 9,
    fontWeight: 600,
    fontStyle: 'italic',
    color: '#3B3A39',
    marginTop: 8,
  },
  desc: {
    fontSize: 9,
    fontWeight: 600,
    color: '#3B3A39',
    marginTop: 8,
    marginBottom: 8
  },
  headerWrap: {
    padding: '8px 11px 8px 11px',
    border: '1px solid #E1DFDD',
    backgroundColor: '#FAF9F8',
    marginBottom: 8,
  },
  headerText: {
    fontSize: 11,
    fontWeight: 700,
    textAlign: 'center',
  },
  info: {
    fontSize: 12,
    marginBottom: 12,
  },
  documentRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 6,
  },
  checkboxWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
  },
  checkboxImg: {
    width: 12,
    height: 12,
    marginRight: 6,
  },
  documentLabel: {
    fontSize: 9,
    fontWeight: 400,
    color: '#3B3A39',
  },
  additionalInfo: {
    fontSize: 10,
    color: '#555',
    marginLeft: 20,
  },  
  infoImg: {
    height: 10,
    width: 10,
    marginRight: 5,
    marginTop: 5,
  }
});

export default HomeAssessmentAndSafetyPdfView;
