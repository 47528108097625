import ItemTable from './ItemTable/ItemTable';
import PatientInfo from '../common/PatientInfo/PatientInfo';
import DeliveryInfo from '../common/DeliveryInfo/DeliveryInfo';
import PharmacyInfo from '../common/PharmaInfo/PharmacyInfo';
import HomeAssessmentAndSafety from './HomeAssessmentAndSafety/HomeAssessmentAndSafety';
import ProductSettingAndTestingForm from '../../presentation/DeliveryTicket/ProductSettingAndTestingForm/ProductSettingAndTestingForm';

const DeliveryAssessmentForm = ({
  data,
  formErrors,
  isEdit,
  setDeliveryTicketHandler,
}) => {
  const {
    patientData,
    orderItemData,
    settingAndTestingData,
    deliveryInfo,
    homeAssessmentData,
    productOrder,
  } = data || {};

  return (
    <div className="group-container">
      <PharmacyInfo className="mt-6x" />
      <div className="d-flex justify-content-between gap-4">
        <PatientInfo className="mt-4x flex-1" data={patientData} />
        <DeliveryInfo className="mt-4x flex-1" data={deliveryInfo} />
      </div>

      <ItemTable
        tableData={orderItemData}
        productOrder={productOrder}
        isEdit={isEdit}
        setDeliveryTicketHandler={setDeliveryTicketHandler}
      />

      <ProductSettingAndTestingForm
        settingAndTestingData={settingAndTestingData}
        setDeliveryTicketHandler={setDeliveryTicketHandler}
        isEdit={isEdit}
      />

      <HomeAssessmentAndSafety
        homeAssessmentData={homeAssessmentData}
        setDeliveryTicketHandler={setDeliveryTicketHandler}
        isEdit={isEdit}
      />
    </div>
  );
};

export default DeliveryAssessmentForm;
